/**
 * 兼容 vue2 $set方法
 *
 * @param source 
 * @param key 
 * @param value 
 */
export function setHandler(source: any, key: string | number, value: any) {
  source[key] = value
}
