// @ts-ignore
import NProgress from 'nprogress'
import {ProjectStore} from "@/stores/modules/project";
import { envTitle } from '@/config'
import {RouterNameEnum} from "@/types/enums/pageEnum";

const afterEachHandler = async (to: any, from: any) => {
    const projectStore = ProjectStore()
    NProgress.done()
    const hasCache = from?.meta?.caches?.includes(to.name)
    const cacheMap: any = {
        [RouterNameEnum.BUSINESS]: 'business',
        [RouterNameEnum.BILL]: 'bill'
    }
    if (!hasCache) {
        projectStore.clearSearchStorageItem(cacheMap[from.name])
    }
    if (to.name !== from.name) {
        document.title = (to?.meta?.title || 'KOP') + (envTitle ? `[${envTitle}]` : '')
    }
}

export default afterEachHandler