<template>
  <a-checkbox v-bind="$attrs" :class="getClass">
    <slot />
  </a-checkbox>
</template>

<script>
export default {
  name: 'ImCheckbox',
  props: {
    shape: {
      type: String,
      default: ''
    }
  },
  computed: {
    getClass() {
      const className = []
      if (this.shape === 'circle') {
        className.push('circle')
      }
      return className
    }
  },
}
</script>

<style lang="less">
.ant-checkbox-wrapper {
  &.circle {
    .ant-checkbox-inner {
      border-radius: 100%;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
