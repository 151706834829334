import {createApp} from 'vue'
import pinia from './stores'
import App from './App.vue'
import router from './router/index'
import i18n from './plugins/i18n'
import cookies from 'js-cookie'
import axios from './api/request'
import {setHandler} from './utils/set'
import {message, Modal} from 'ant-design-vue'
import ElementPlus from 'element-plus'
import 'element-plus/es/components/date-picker/style/css'
import {createConfirm} from '@/components/dialog/confirm/index.js'
import Im30Components from '@/components/im30/index'
import version from './plugins/version'
// import sentry from './plugins/sentry'

import 'ant-design-vue/es/message/style/index.css'
import 'nprogress/nprogress.css'
import './static/iconfont/iconfont.css'
import './static/iconfont/iconfont.js'
import './assets/style/tailwind.less'
import './assets/style/index.less'

const app = createApp(App)
    .use(ElementPlus)
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(version)
    // .use(sentry)
    .use(Im30Components)
// 全局变量
app.config.globalProperties.$cookies = cookies
app.config.globalProperties.$axios = axios
app.config.globalProperties.$set = setHandler
app.config.globalProperties.$cfm = createConfirm
app.config.globalProperties.$message = message
app.config.globalProperties.$confirm = Modal.confirm
app.config.globalProperties.$info = Modal.info
app.config.globalProperties.$success = Modal.success
app.config.globalProperties.$error = Modal.error
app.config.globalProperties.$warning = Modal.warning

app.mount('#app')
