import request from '@/api/request'

const Api = {
  list: '/api/v2/merchant/virtual-currency/get',
  // detail: '/api/v1/merchant/store/activity',
  add: '/api/v2/merchant/virtual-currency/create',
  update: '/api/v2/merchant/virtual-currency/update',
  delete: '/api/v2/merchant/virtual-currency/delete',
}

// 货币列表
export const getCurrencyList = (params: any) => {
  return request.get(Api.list, { params })
}

// 货币详情
// export const getCurrencyDetail = (params: any) => {
//   return request.get(`${Api.detail}/${params.activity_id}`, { params })
// }

// 新建货币
export const addCurrency = (data: any) => {
  return request.post(Api.add, data)
}

// 更新货币
export const updateCurrency = (data: any) => {
  return request.post(Api.update, data)
}

// 删除货币
export const deleteCurrency = (data: any) => {
  return request.post(Api.delete, data)
}

