<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ImIcon",
  
})
</script>
<template>
  <svg class="icon">
    <use :xlink:href="`#${props.icon}`" />
  </svg>
</template>

<script setup name="ImIcon" lang="ts">
const props = defineProps({
  icon: {
    type: String,
    default: ''
  }
})
</script>
