import {createRouter, createWebHistory} from 'vue-router'
import beforeEachHandler from './beforeEachHandler'
import afterEachHandler from './afterEachHandler'
import { baseRoutes } from './routesList'

const router = createRouter({
    history: createWebHistory(),
    routes: baseRoutes,
})

router.beforeEach(beforeEachHandler);
router.afterEach(afterEachHandler)

export default router;

