import request from '@/api/request'

const Api = {
  list: '/api/v2/admin/sale/biz/list',
  add: '/api/v2/admin/sale/biz/create',
  update: '/api/v2/admin/sale/biz/update',
  items: '/api/v2/admin/sale/filter/goods',
  allGoods: '/api/v2/admin/sale/list/goods',
  status: '/api/v2/admin/sale/biz/status/edit',
  signStatistic: '/api/v2/merchant/store/activity/sign-in/statistic',
  signCycles: '/api/v2/merchant/store/activity/sign-in/cycles'
}

// 活动列表
export const getActivityList = (params: any) => {
  return request.get(Api.list, { params })
}

// 新建活动
export const addActivity = (data: any) => {
  return request.post(Api.add, data)
}

// 更新活动
export const updateActivity = (data: any) => {
  return request.post(Api.update, data)
}

// 更新活动状态
export const updateActivityStatus = (data: any) => {
  return request.post(Api.status, data)
}

export const querySignStatistic = (params: any) => {
  return request.get(Api.signStatistic, { params })
}

export const queryRechargeStatistic = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/continuous-recharge/statistic', { params })
}

// 虚拟物品列表-查询
export const getActivityItems = (params: any) => {
  return request.get(Api.items, { params })
}

export const queryLotteryStatistic = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/lottery/statistic', { params })
}

export const queryLotteryDetail = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/lottery/result-list', { params })
}

export const exportLotteryDetail = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/lottery/result-export', { responseType: 'blob', params })
}

export const getFullDiscountStatistic = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/full-discount/statistic-list', { params })
}

export const exportFullDiscount = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/full-discount/statistic-export', { responseType: 'blob', params })
}

export const getFullDiscountOptions = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/full-discount/level-list', { params })
}

export const createCoupon = (data: any) => {
  return request.post('/api/v2/merchant/store/activity/coupons/create', data)
}

export const updateCoupon = (data: any) => {
  return request.post('/api/v2/merchant/store/activity/coupons/update', data)
}

export const getCouponList = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/coupons/list', { params })
}

export const disabledCoupon = (data: any) => {
  return request.post('/api/v2/merchant/store/activity/coupons/invalid', data)
}

export const deleteCoupon = (data: any) => {
  return request.post('/api/v2/merchant/store/activity/coupons/delete', data)
}

export const exportCouponReplay = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/coupons/statistic-export', { responseType: 'blob', params })
}

export const detailCoupon = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/coupons/detail', { params })
}

export const getCouponStatic = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/coupons/statistic', { params })
}

export const getSignCycles = (params: any) => {
  return request.get(Api.signCycles, { params })
}

export const getAddUpReplayList = (params: any) => {
  return request.get('/api/v2/admin/sale/biz/add-credit/statistic', { params })
}

export const getAddUpResultList = (params: any)=> {
  return request.get('/api/v2/admin/sale/biz/add-credit/result/list', { params })
}

export const exportAddUpResult = (params: any) => {
  return request.get('/api/v2/admin/sale/biz/add-credit/result/export', { responseType: 'blob', params })
}

export const getConsecutiveResult = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/continuous-recharge/result/list', { params })
}

export const exportConsecutiveResult = (params: any) => {
  return request.get('/api/v2/merchant/store/activity/continuous-recharge/result/export', { responseType: 'blob', params })
}

export const getAllGoods = (params: any) => {
  return request.get(Api.allGoods, { params })
}