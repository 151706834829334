import request from '@/api/request'
import type { BasePagination } from '@/types/interface/base'
import type { LoginRequest, RegisterUserRequest, ResetPasswordRequest, VerifyCodeRequest } from '@/types/interface/userInterface'
// @ts-ignore
import type {InviteUserType, UserEditRequest} from '@/types/type/userType'

const Api = {
  RegisterCompany: '/api/v1/merchant/user/gallery/edit',
  Logout: '/api/v1/merchant/user/logout',
  ResetPassword: '/api/v1/merchant/user/pwd/edit',
  VerifyCode: '/api/v1/merchant/user/verify',
  GetPermCode: '/api/v1/merchant/getPermCode',
  ForgetPassword: '/api/v1/merchant/user/forget_password',
}

// export const doLogout = (params: any) => request.get(Api.Logout, { params })

// export const registerCompany = (data: any) => request.post(Api.RegisterCompany, data)

/**
 * 获取 aes加密 key和标识
 *
 * @returns
 */
export const getAesKey = () => request.get('/api/v2/encrypt/aes')

/**
 * 获取当前用户的商户列表
 *
 * @returns
 */
export const getMyMerchantList = (params: BasePagination) => request.get('/api/v2/user/merchant/list', { params })

/**
 * 获取验证码
 *
 * @param data
 * @returns
 */
export const sendVerifyCode = (data: VerifyCodeRequest) => request.post('/api/v2/send-code', data)

/**
 * 用户注册
 *
 * @param data
 * @returns
 */
export const registerUser = (data: RegisterUserRequest) => request.post('/api/v2/register', data)

/**
 * 用户登录
 *
 * @param data
 * @returns
 */
export const login = (data: LoginRequest) => request.post('/api/v2/login', data)

export const getUsers = (params: object) => request.get('/api/v2/merchant/user-list', { params })

export const deleteUser = (params: object) => request.get('/api/v2/merchant/user/delete', { params })

export const editUserRole = (data: object) => request.post('/api/v2/merchant/user/update', data)

export const getUser = (params: object) => request.get('/api/v2/merchant/user-detail', { params })

export const inviteUser = (data: InviteUserType) => request.post('/api/v2/merchant/invite', data)

/**
 * 获取用户信息
 *
 * @param token
 * @returns
 */
export const getUserInfo = (token?: string) => {
  const config = token
    ? { Authorization: token }
    : {}
  return request({
    method: 'get',
    url: '/api/v2/user/info',
    ...config,
  })
}

/**
 * 重置密码
 *
 * @param data
 * @returns
 */
export const resetPassword = (data: ResetPasswordRequest) => request.post('/api/v2/reset-password', data)

/**
 * 修改用户信息
 *
 * @param data 
 * @returns 
 */
export const editUser = (data: UserEditRequest) => request.post('/api/v2/user/edit', data)


export const checkUserEmail = (params: object) => request.get('/api/v2/merchant/invite/available', { params })