import imButton from './button/index.vue'
import imCheckbox from './checkbox/index.vue'

const components = [
  imButton,
  imCheckbox
]

export default {
  install(app: any) {
    components.forEach(item => app.component(item.name, item))
  }
}
