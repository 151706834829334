import { createI18n } from 'vue-i18n'
// @ts-ignore
import cn from '@/locales/cn.json'
// @ts-ignore
import en from '@/locales/en.json'

// Create i18n instance with options
export const i18n = createI18n({
  legacy: false,
  locale: 'cn',
  allowComposition: true,
  fallbackLocale: 'cn',
  messages: {
    cn,
    en
  }
})

export const t = i18n.global.t

export const systemLanguage = {
  cn: 'cn',
  en: 'en',
  tw: 'tw'
}

export default i18n
