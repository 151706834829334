export const DefaultCurrency = 'USD'
export const isPre = import.meta.env.VITE_RUN_ENV === 'pre'
export const isTest = import.meta.env.VITE_RUN_ENV === 'test'
export const isRelease = import.meta.env.VITE_RUN_ENV === 'release'

export const isDev = import.meta.env.VITE_RUN_ENV === 'dev'

export const baseDomain = 'kopglobal.com'


export const envTitle = isPre ? '预发布' : isTest ? '测试' : isDev ? '开发' : ''

export const PlaceholderId = ':projectId'