// @ts-nocheck
import NProgress from 'nprogress'
import { i18n } from '@/plugins/i18n'
import  { TokenName } from '@/config/cookieKeys';
import { PageEnum } from '@/types/enums/pageEnum';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { routesWhiteList } from './routesList';
import cookies from 'js-cookie'
import {ProjectStore} from '@/stores/modules/project';
import {getProject} from '@/api/project';
import {message} from 'ant-design-vue';
import { generateRoutes, setUserInfo } from './permission'
import { PermissionStore } from '@/stores/modules/permission'
import {getSystemLanguage} from "@/api/multiLanguage";

let languageReady = false
const getLanguageData = async () => {
  if (languageReady) return
  const lang = localStorage.getItem('lang') || 'cn'
  const params = {
    language_code: lang,
    category: 12
  }
  const res = await getSystemLanguage(params)
  i18n.global.locale.value = lang
  i18n.global.mergeLocaleMessage(lang, res.key_list)
  languageReady = true
}

const beforeEach = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
  const toPath = to.path
  const permissionStore = PermissionStore()
  const cookiesToken = cookies.get(TokenName)
  await getLanguageData()
  if (routesWhiteList.includes(toPath)) {
    const isResetPassword = to?.params?.type === 'reset'
    const isLogin = toPath === PageEnum.BASE_LOGIN
    const isInvite = isLogin && to.query.type === 'invite'
    return ((cookiesToken && isLogin) && (!isResetPassword && !isInvite)) ? next(PageEnum.BASE_DASHBOARD) : next()
  }

  NProgress.start()
  const setProjectInfo = async () => {
    const projectId = (to?.params?.projectId) || to.params.pathMatch[2]
    if (!projectId || typeof projectId !== 'string') {
      return next(PageEnum.ERROR_PAGE)
    }
    const projectSore = ProjectStore()
    if (!Object.keys(projectSore.info).length || projectSore?.info?.project_id !== +projectId) {
      try {
        const project = await getProject({
          project_id: projectId
        })
        projectSore.SET_INFO(project)
        projectSore.SET_PROJECT_ID(String(projectId))
        projectSore.SET_PROJECT_NO(project.project_no)
      } catch (error: any) {
        message.error(error?.message || '获取应用详情失败')
        console.error(error)
      }
    }
  }
  if (cookiesToken) {
    await setUserInfo(next)
    const [asyncRoutes] = generateRoutes()
    const isProjectMenu = toPath.includes('projectManage/project/')
    if (isProjectMenu) {
      await setProjectInfo()
    }
    if (!permissionStore.routes.length) {
      permissionStore.setRoutes(asyncRoutes)
      return next({ path: to.path, replace: true, query: to.query })
    } else {
      return next()
    }
  }

  return next(PageEnum.BASE_LOGIN)
}

export default beforeEach
