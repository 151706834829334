import { defineStore } from "pinia"
import type { UserInfoType } from "@/types/interface/userInterface";

type userType = UserInfoType | null;

export const UserStore = defineStore('user', {
  state: () => ({
    user: null as userType,
    userNo: '',
    firstLogin: false,
    tutorialEnable: false
  }),

  actions: {
    setTutorialEnable(state: any) {
      this.tutorialEnable = state
    },
    setFirstLogin(state: any) {
      this.firstLogin = state
    },
    SET_USER(user: UserInfoType) {
      this.user = user
      this.userNo = user?.id || ''
    },
    clearUser() {
      this.user = null
      this.userNo = ''
    },
    SET_CURRENT_MERCHANT_NO(payload: string) {
      if (this.user) {
        this.user.current_merchant_id = payload
      }
    }
  }
});
