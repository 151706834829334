import request from '@/api/request'

// 应用多语言导出
export const exportLanguageData = (params: any) => request({
  url: '/api/v2/merchant/site/i18n-export',
  method: 'get',
  params,
  responseType: 'blob',
})

// 应用多语言导入
export const importLanguageFile = (data: any) => {
  return request({
    url: '/api/v2/merchant/site/i18n-import',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// 应用多语言编辑
export const projectLanguageEdit = (data: any) => {
  return request({
    url: '/api/v2/merchant/project/update-language',
    method: 'post',
    data,
  })
}

export const getLangIdData = (params: any) => {
  return request({
    url: '/api/v2/base/site/i18n',
    method: 'get',
    params
  })
}

export const getSystemLanguage = (params: any) => {
  return request({
    url: '/api/v2/base/category/tran',
    method: 'get',
    params
  })
}