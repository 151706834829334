export const PermissionCodeEnum = {
    Dashboard: 'Dashboard',
    DashboardWithdrawCash: 'DashboardWithdrawCash',
    Transaction: 'Transaction',
    Business: 'Business',
    BusinessDetail: 'BusinessDetail',
    BusinessView: 'BusinessView',
    BusinessExport: 'BusinessExport',
    BillReissue: 'BillReissue',
    BillReissueView: 'BillReissueView',
    Settlement: 'Settlement',
    SettlementView: 'SettlementView',
    SettlementExport: 'SettlementExport',
    Chargeback: 'Chargeback',
    ChargebackView: 'ChargebackView',
    ChargebackEdit: 'ChargebackEdit',
    ChargebackExport: 'ChargebackExport',
    Bill: 'Bill',
    BillMenu: 'BillMenu',
    BillDetail: 'BillDetail',
    BillDetailView: 'BillDetailView',
    BillView: 'BillView',
    BillRefund: 'BillRefund',
    BillExport: 'BillExport',
    Analyze: 'Analyze',
    AnalyzeStream: 'AnalyzeStream',
    AnalyzeStreamView: 'AnalyzeStreamView',
    AnalyzePayUser: 'AnalyzePayUser',
    AnalyzePayUserView: 'AnalyzePayUserView',
    AnalyzeRate: 'AnalyzeRate',
    AnalyzeRateView: 'AnalyzeRateView',
    AnalyzePaymentRank: 'AnalyzePaymentRank',
    AnalyzePaymentRankView: 'AnalyzePaymentRankView',
    AnalyzeVisits: 'AnalyzeVisits',
    AnalyzeVisitsView: 'AnalyzeVisitsView',
    Goods: 'Goods',
    VirtualGoods: 'VirtualGoods',
    ConvertKey: 'ConvertKey',
    ConvertKeyView: 'ConvertKeyView',
    ConvertKeyEdit: 'ConvertKeyEdit',
    CoverKeyCreate: 'CoverKeyCreate',
    GoodsManage: 'GoodsManage',
    GoodsManageView: 'GoodsManageView',
    GoodsManageEdit: 'GoodsManageEdit',
    GoodsClassify: 'GoodsClassify',
    GoodsClassifyView: 'GoodsClassifyView',
    GoodsClassifyEdit: 'GoodsClassifyEdit',
    MonthCard: 'MonthCard',
    MonthCardView: 'MonthCardView',
    MonthCardCreate: 'MonthCardCreate',
    MonthCardEdit: 'MonthCardEdit',
    VirtualGoodsView: 'VirtualGoodsView',
    VirtualGoodsEdit: 'VirtualGoodsEdit',
    VirtualCurrency: 'VirtualCurrency',
    VirtualCurrencyView: 'VirtualCurrencyView',
    VirtualCurrencyEdit: 'VirtualCurrencyEdit',
    VirtualActivity: 'VirtualActivity',
    VirtualActivityDetail: 'VirtualActivityDetail',
    VirtualActivityDetailView: 'VirtualActivityDetailView',
    VirtualActivityView: 'VirtualActivityView',
    VirtualActivityEdit: 'VirtualActivityEdit',
    ActivityReplay: 'ActivityReplay',
    ActivityReplayView: 'ActivityReplayView',
    ActivityReplayEdit: 'ActivityReplayEdit',
    Recharge: 'Recharge',
    RechargeView: 'RechargeView',
    RechargeEdit: 'RechargeEdit',
    RechargeCurrency: 'RechargeCurrency',
    RechargeCurrencyView: 'RechargeCurrencyView',
    RechargeCurrencyEdit: 'RechargeCurrencyEdit',
    RechargeValue: 'RechargeValue',
    RechargeValueView: 'RechargeValueView',
    RechargeValueEdit: 'RechargeValueEdit',
    Payment: 'Payment',
    PaymentType: 'PaymentType',
    PaymentTypeView: 'PaymentTypeView',
    PaymentTypeEdit: 'PaymentTypeEdit',
    PaymentSort: 'PaymentSort',
    PaymentSortView: 'PaymentSortView',
    PaymentSortEdit: 'PaymentSortEdit',
    PaymentConfig: 'PaymentConfig',
    PaymentConfigView: 'PaymentConfigView',
    PaymentConfigEdit: 'PaymentConfigEdit',
    Store: 'Store',
    StoreView: 'StoreView',
    StoreEdit: 'StoreEdit',
    StorePublish: 'StorePublish',
    StoreDomain: 'StoreDomain',
    StoreDomainAdd: 'StoreDomainAdd',
    StoreDomainChange: 'StoreDomainChange',
    StoreVisitorCountryChange: 'StoreVisitorCountryChange',
    StoreRegisterLocalPayChange: 'StoreRegisterLocalPayChange',
    StorePage: 'StorePage',
    StorePageAdd: 'StorePage',
    StorePageDelete: 'StorePageDelete',
    StoreUser: 'StoreUser',
    StoreUserSave: 'StoreUserSave',
    StoreFlowOrigin: 'StoreFlowOrigin',
    StoreFlowOriginAdd: 'StoreFlowOriginAdd',
    StoreFlowOriginDelete: 'StoreFlowOriginDelete',
    UserGroupView: 'UserGroupView',
    UserGroupCreate: 'UserGroupCreate',
    UserGroup: 'UserGroup',
    App: 'App',
    AppDetail: 'AppDetail',
    AppDetailView: 'AppDetailView',
    AppDetailEdit: 'AppDetailEdit',
    AppWebhook: 'AppWebhook',
    AppWebhookView: 'AppWebhookView',
    AppWebhookEdit: 'AppWebhookEdit',
    PriceTemplate: 'PriceTemplate',
    PriceTemplateView: 'PriceTemplateView',
    PriceTemplateEdit: 'PriceTemplateEdit',
    AutoUpdatePrice: 'AutoUpdatePrice',
    AutoUpdatePriceView: 'AutoUpdatePriceView',
    AutoUpdatePriceEdit: 'AutoUpdatePriceEdit',
    RiskManage: 'RiskManage',
    RiskManageView: 'RiskManageView',
    RiskManageEdit: 'RiskManageEdit',
    LanguageManage: 'LanguageManage',
    LanguageManageView: 'LanguageManageView',
    LanguageManageEdit: 'LanguageManageEdit',
    Permission: 'Permission',
    PermissionUser: 'PermissionUser',
    PermissionUserView: 'PermissionUserView',
    PermissionUserViewEdit: 'PermissionUserViewEdit',
    PermissionUserCreate: 'PermissionUserCreate',
    PermissionUserDetail: 'PermissionUserDetail',
    PermissionCreateRole: 'PermissionCreateRole',
    PermissionUserInvite: 'PermissionUserInvite',
    PermissionCreateRoleView: 'PermissionCreateRoleView',
    PermissionCreateRoleEdit: 'PermissionCreateRoleEdit',
    PermissionCreateRoleNew: 'PermissionCreateRoleNew',
    PermissionRoleDetail: 'PermissionRoleDetail',
    PermissionMerchantSetting: 'PermissionMerchantSetting',
    PermissionMerchantSettingView: 'PermissionMerchantSettingView',
    PermissionMerchantSettingEdit: 'PermissionMerchantSettingEdit',
    Activity: 'Activity',
    ActivityXy: 'ActivityXy',
    ActivityXyCreate: 'ActivityXyCreate',
    ActivityXyView: 'ActivityXyView',
    ActivityXyEdit: 'ActivityXyEdit',
    ActivityAddUp: 'ActivityAddUp',
    ActivityAddUpView: 'ActivityAddUpView',
    ActivityAddUpEdit: 'ActivityAddUpEdit',
    ActivityAddUpCreate: 'ActivityAddUpCreate',
    ActivityAddUpReplay: 'ActivityAddUpReplay',
    ActivityFirstBuy: 'ActivityFirstBuy',
    ActivityFirstBuyView: 'ActivityFirstBuyView',
    ActivityFirstBuyEdit: 'ActivityFirstBuyEdit',
    ActivityFirstBuyCreate: 'ActivityFirstBuyCreate',
    ActivityLimit: 'ActivityLimit',
    ActivityLimitView: 'ActivityLimitView',
    ActivityLimitEdit: 'ActivityLimitEdit',
    ActivityLimitCreate: 'ActivityLimitCreate',
    ActivityRecharge: 'ActivityRecharge',
    ActivityRechargeView: 'ActivityRechargeView',
    ActivityRechargeEdit: 'ActivityRechargeEdit',
    ActivityRechargeCreate: 'ActivityRechargeCreate',
    ActivitySignIn: 'ActivitySignIn',
    ActivitySignInView: 'ActivitySignInView',
    ActivitySignInEdit: 'ActivitySignInEdit',
    ActivitySignInCreate: 'ActivitySignInCreate',
    ActivitySignInReplay: 'ActivitySignReplay',
    ActivityConsecutive: 'ActivityConsecutive',
    ActivityConsecutiveView: 'ActivityConsecutiveView',
    ActivityConsecutiveEdit: 'ActivityConsecutiveEdit',
    ActivityConsecutiveReplay: 'ActivityConsecutiveReplay',
    ActivityConsecutiveCreate: 'ActivityConsecutiveCreate',
    ActivityLottery: 'ActivityLottery',
    ActivityLotteryView: 'ActivityLotteryView',
    ActivityLotteryEdit: 'ActivityLotteryEdit',
    ActivityLotteryCreate: 'ActivityLotteryCreate',
    ActivityLotteryReplay: 'ActivityLotteryReplay',
    ActivityFullDiscount: 'ActivityFullDiscount',
    ActivityFullDiscountView: 'ActivityFullDiscountView',
    ActivityFullDiscountEdit: 'ActivityFullDiscountEdit',
    ActivityFullDiscountCreate: 'ActivityFullDiscountCreate',
    ActivityFullDiscountReplay: 'ActivityFullDiscountReplay',
    ActivityCoupon: 'ActivityCoupon',
    ActivityCouponView: 'ActivityCouponView',
    ActivityCouponEdit: 'ActivityCouponEdit',
    ActivityCouponReplay: 'ActivityCouponReplay',
    ActivityInviteCode: 'ActivityInviteCode',
    ActivityInviteCodeView: 'ActivityInviteCodeView',
    ActivityInviteCodeEdit: 'ActivityInviteCodeEdit',
    ReplacePayment: 'ReplacePayment',
    ReplacePaymentView: 'ReplacePaymentView',
    ReplacePaymentExport: 'ReplacePaymentExport',
    RechargeHistory: 'RechargeHistory',
    RechargeHistoryView: 'RechargeHistoryView',
    WithdrawCash: 'WithdrawCash',
    WithdrawCashView: 'WithdrawCashView',
    WithdrawCashExport: 'WithdrawCashExport'
}